import {gql} from '@apollo/client'
import React, {useContext} from 'react'
import Page from '../components/page'
import {ProviderStatsSummary, StatsPage} from '../components/stats'
import {AppContext} from '../context'

export const GET_STATS = gql`
  query MyStatsStatsQuery($dateRange: DateRange) {
    stats(provider: "self", dateRange: $dateRange) {
      id

      metric
      date
      updatedAt
      value

      provider {
        id
        picture
        name
      }

      tag {
        id
        name
        color
        level
      }
    }
  }
`

const MyStatsContent: React.FC = () => {
  const {user} = useContext(AppContext)
  return (
    <StatsPage
      query={GET_STATS}
      StatSummary={ProviderStatsSummary}
      title="My Stats"
      emptyError="It looks like you don't have any stats"
      provider={user}
    />
  )
}

const MyStatsPage: React.FC = () => (
  <Page title="My Stats">
    <MyStatsContent />
  </Page>
)

export default MyStatsPage
